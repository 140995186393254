<div class="container-10">
  <div  fxLayout="row wrap" fxLayout.xs="column"  fxLayoutAlign="center">

    <form (ngSubmit)="addUser()" #domainForm="ngForm" style="width: 80%;">
      <div fxLayout="column" fxLayoutGap="20px">

        <div fxLayout="column">
          <div fxFlex="200">
            <label for="name"><h3>User email</h3></label>
            <p class="helptext">User email to allow access for</p>
          </div>
          <div fxFlex="200">
            <mat-form-field>
              <input matInput type="email" id="email" name="email" placeholder="email"  [(ngModel)]="allowedUser.email" required email  #email >
            </mat-form-field> 
          </div>
        </div>


        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
          <button mat-button type="button" (click)="deleteUser()">Delete</button>
          <div style="width: 30px;"></div>
          <button mat-button type="button" (click)="cancel()">Cancel</button>
          <button mat-button type="submit" [disabled]="!domainForm.form.valid">Save</button>
        </div>

      </div>
    </form>

  </div>
</div>



<div class="container">


  <form (ngSubmit)="saveSysadmin()" #channelForm="ngForm">
    <div fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="name"><h3>Sys admin email</h3></label>
          <p class="helptext">The (primary) email address of the user who should have the sys admin role</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <!--<input matInput placeholder="Editor email" [(ngModel)]="this.sysadmin.email">-->

            <input id="name" name="name"
                   matInput placeholder="Email" [formControl]="emailFormControl"
                   [errorStateMatcher]="matcher" [(ngModel)]="this.sysadmin.email">
            <mat-hint></mat-hint>
            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>


      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <!--<button mat-button type="button" (click)="HandleDelete()">Delete</button>-->
        <!--<div style="width: 30px;"></div>-->
        <button mat-button type="button" (click)="handleCancel()">Cancel</button>
        <button mat-button type="submit" [disabled]="emailFormControl.invalid">Save</button>
      </div>

    </div>
  </form>
</div>


<mat-list *ngFor="let sysadmin of this.sysadmins; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="sysadminLine">
    <div class="userline" (click)="editSysadmin(sysadmin)">
      <div class="image"><img src="{{sysadmin.photoUrl}}" class="photo"></div>
      <div class="user">{{sysadmin.name}} &lt;{{sysadmin.email}}&gt;</div>
    </div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newSysadmin()" class="sticky-add-button" matTooltip="New Sysadmin"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>
import { Router } from '@angular/router';
import { RolesService } from '../services/roles.service';
import { AuthService } from '../services/auth.service';
import { map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/roles.service";
import * as i2 from "../services/auth.service";
import * as i3 from "@angular/router";
export class AdminGuard {
    constructor(rolesService, authService, router) {
        this.rolesService = rolesService;
        this.authService = authService;
        this.router = router;
    }
    canActivate(next, state) {
        // TODO: check this method later again if this is right
        /**
         * Checks if user is an editor, if so, then it may activate the editor component
         *
         */
        return this.authService.getLoggedInUser().pipe(mergeMap(result => {
            if (result) {
                return this.rolesService.detectAdmin(result.email, '', '', '').pipe(map(isEditor => {
                    if (isEditor) {
                        return true;
                    }
                    else {
                        this.router.navigate(['admin']);
                    }
                }));
            }
        }));
    }
}
AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.RolesService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router)); }, token: AdminGuard, providedIn: "root" });

/**
 * EditorModel     extends RoleModel
 *
 * Some users can have the Editor role. These users are stored in the role node of fireBase, so they can be identified
 * after login.
 *
 */
import { RoleModel } from './role-model';
import { isNullOrUndefined } from 'util';
export class EditorModel extends RoleModel {
    constructor() {
        super(...arguments);
        this._allChannels = false; // Does the editor have access to all channels?
        this._channels = []; // List of channelDef.keys, linking the article to one or more channels
    }
    /**
     * fromFirebase()
     *
     * Returns an ArticleModel constructed from fireBase data
     *
     * @param inFireNode          any   data as received from fireBase
     * @returns {ArticleModel}
     */
    static fromFirebase(inFireNode) {
        // Create a new empty model
        const theModel = new EditorModel();
        theModel.fromFirebaseData(inFireNode);
        return theModel;
    }
    /**
     * EditorModel()
     *
     * @constructor
     */
    EditorModel() {
    }
    /**
     * fromFirebaseData()
     *
     * Parses the fireBase data
     *
     * @param inFireNode
     */
    fromFirebaseData(inFireNode) {
        super.fromFirebaseData(inFireNode);
        // Retirieve the payload of the firebase data
        const thePayload = inFireNode.payload.val();
        if (!isNullOrUndefined(thePayload.channels)) {
            this._channels = thePayload.channels;
        }
        if (!isNullOrUndefined(thePayload.allChannels)) {
            this._allChannels = thePayload.allChannels;
        }
    }
    toString() {
        return JSON.stringify(this);
    }
    /**
     * toFirebase()
     *
     * Returns a fireBase node with data from the object
     *
     * @returns {any}
     */
    toFirebase() {
        const theObj = super.toFirebase();
        theObj.channels = this._channels;
        theObj.allChannels = this._allChannels;
        return theObj;
    }
    /**
     * hasAccess()
     *
     * Returns true if a user is an editor and has access to the
     * publication channel referred to in inChannelDefKey
     *
     * If no inChannelDefKey is specified, true will be returned
     *
     * @param {string} inChannelDefKey
     * @returns {boolean}
     */
    hasAccess(inChannelDefKey) {
        let hasAccess = false;
        if (!isNullOrUndefined(inChannelDefKey) && inChannelDefKey.length > 0) {
            // No channel specification has been requested.
            // User is an editor so hasAccess is true
            hasAccess = true;
        }
        else {
            // Access to a specific channel has been requested
            if (this._allChannels) {
                // Editor has access to all channels
                hasAccess = true;
            }
            else {
                // Access of the editor is limited to one or more channels
                // Find out if this channel is one of them
                if (this._channels.indexOf(inChannelDefKey) >= 0) {
                    hasAccess = true;
                }
            }
        }
        return hasAccess;
    }
    addChannel(inChannelDefKey) {
        // Find the index of the key
        const theIndex = this.channels.indexOf(inChannelDefKey);
        // If it is NOT present, add it
        if (theIndex < 0) {
            this.channels.push(inChannelDefKey);
        }
    }
    removeChannel(inChannelDefKey) {
        // Find the index of the key
        const theIndex = this.channels.indexOf(inChannelDefKey);
        // If it is present, remove it
        if (theIndex >= 0) {
            this.channels.splice(theIndex, 1);
        }
    }
    /*
  
  GETTERS AND SETTERS
  
  */
    get allChannels() {
        return this._allChannels;
    }
    set allChannels(value) {
        this._allChannels = value;
    }
    get channels() {
        return this._channels;
    }
    set channels(value) {
        this._channels = value;
    }
}

<div fxLayout="column">

  <div>
    <h1 class="mat-dialog-title" *ngIf="data.title">Select the main image</h1>
  </div>

  <div>
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="center"  fxLayoutGap="10px">
        <div fxFlex="{{layoutSize}}}" *ngFor="let imageSrc of data.images"><img src="{{imageSrc}}" (click)="selectImage(imageSrc)" class="image-content"> </div>
      </div>
    </mat-dialog-content>

    <!-- <div class="mat-dialog-actions">
      <button mat-button (click)="closeDialog(true)" [mat-dialog-close]="true" *ngIf="data.affirmativeText">{{data.affirmativeText}}</button>
      <button mat-button (click)="closeDialog(false)" [mat-dialog-close]="false" *ngIf="data.declineText">{{data.declineText}}</button>

    </div> -->
  </div>

</div>
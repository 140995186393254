var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * ArticleListViewComponent
 *
 * Displays a collection of articles in various ways
 *
 * @Input()  canEdit:    boolean                      Does the current user have edit privileges
 * @Output() open:       EventEmitter<ArticleModel>   Emitted when a user clicks on the title to open the article
 * @Output() edit:       EventEmitter<ArticleModel>   Emitted when a user/editor clicks on the edit button to edit the article
 */
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable, of, Subject, combineLatest } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import { RolesService } from '../../services/roles.service';
import { catchError, first, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
export class ArticleListViewComponent {
    constructor(articleService, settingsService, rolesService, router, route) {
        this.articleService = articleService;
        this.settingsService = settingsService;
        this.rolesService = rolesService;
        this.router = router;
        this.route = route;
        this.open = new EventEmitter();
        this.edit = new EventEmitter();
        this.alphaOrder = false;
        this.pubDateOrder = false;
        this.pubOrder = false;
        this.listExpanded = false;
        this.articles = [];
        this.filteredArticles = [];
        this.channelDefs = [];
        this.selected = [];
        this.saveSearchString = '';
        this.queryField = new FormControl();
        this.loadingFilter = false;
    }
    ngOnInit() {
        // Subscribe to the ChannelDefs
        this.setArticles();
        // this.setPreselectedFilter(); 
        this.articles$ = this.articleService.getArticlesFromIndex(null).pipe(map(articles => articles.sort(this.sortPublicationDefault)), catchError(error => {
            this.isError$.next(true);
            return of(null);
        }));
        this.publishedArticles$ = this.articleService.getArticlesFromIndex(null).pipe(map(articles => {
            const ta = [];
            if (articles.some(article => article.published)) {
                ta.push;
            }
            return ta;
        }));
        this.channelDefs$ = this.settingsService.retrieveChannelDefs();
        this.setChannelOnInit();
    }
    ngOnDestroy() {
        // Unsubscribe on component destroy
        this.articleListSubscription.unsubscribe();
        // this.channelDefSubscription.unsubscribe();
        // this.queryParamsSubscription.unsubscribe();
    }
    /**
      * This method is called in ngOninit
      *
      * Retrieves all articles and sets them to a two global variables; original article list and filtered article lists
      * This is needed for filtering
      *
      * setChannelDefs()
      */
    setArticles() {
        // Subscribe to the Article list
        this.articleListSubscription = this.articleService.getArticlesFromIndex(null).subscribe((inArticles) => {
            this.articles = inArticles;
            this.filteredArticles = inArticles;
            this.filteredArticles.sort(this.sortPublicationDefault);
            // this.setChannelDefs();
        });
    }
    // searchArticles(inQuery: string) {
    //   this.articleService.searchArticle(inQuery).subscribe(result => {
    //     console.log(result);
    //   })
    // }
    loadMoreArticles(inArticles) {
        return __awaiter(this, void 0, void 0, function* () {
            const lastArticle = inArticles.slice(-1)[0];
            const moreArticles = yield this.articleService.getArticlesFromIndex(lastArticle).pipe(first()).toPromise();
            moreArticles.forEach(article => {
                if (!inArticles.some(ar => ar.key === article.key)) {
                    inArticles.push(article);
                }
            });
            inArticles.sort(this.sortPublicationDefault);
        });
    }
    /**
     * Presets the filters for the article list
     * Checks if the requested parameter is an  array
     * setPreselectedFilter()
     */
    setChannelOnInit() {
        const theParams$ = this.route.queryParams;
        const channelDefs$ = this.settingsService.retrieveChannelDefs();
        combineLatest(theParams$, channelDefs$, (params, channelDefs) => {
            const channelLabels = params['channels'];
            if (channelLabels) {
                this.selected = this.getLabelsFromKey(channelLabels, channelDefs);
                this.filterList(this.selected);
            }
        }).pipe(first()).toPromise();
    }
    getLabelsFromKey(inChannelLabels, inChannelDefs) {
        const preselect = [];
        if (Array.isArray(inChannelLabels)) {
            inChannelDefs.forEach(channelDef => {
                if (inChannelLabels.indexOf(channelDef.label) >= 0) {
                    preselect.push(channelDef.key);
                }
            });
        }
        else {
            inChannelDefs.forEach(channelDef => {
                if (channelDef.label === inChannelLabels) {
                    preselect.push(channelDef.key);
                }
            });
        }
        return preselect;
    }
    openArticle(inArticle) {
        this.router.navigate(['/articles/view', inArticle.key]);
    }
    newArticle() {
        // console.log('newarticle');
        this.router.navigate(['/articles/edit', '']);
    }
    /**
     *
     * Sorts the article alphabetically
     *
     * sortAlphabetically()
     */
    sortAlphabetically() {
        this.alphaOrder = !this.alphaOrder;
        this.filteredArticles.sort((a, b) => {
            if (this.alphaOrder) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
            }
            else {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return 1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return -1;
                }
            }
            return 0;
        });
    }
    /**
     *
     * Sorts the article array on the last updated date of an article
     *
     * sortPublicationdate()
     */
    sortPublicationdate() {
        this.pubDateOrder = !this.pubDateOrder;
        this.filteredArticles.sort((a, b) => {
            if (this.pubDateOrder) {
                if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) {
                    return -1;
                }
                if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) {
                    return 1;
                }
            }
            else {
                if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) {
                    return 1;
                }
                if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) {
                    return -1;
                }
            }
            return 0;
        });
    }
    sortPublicationDefault(a, b) {
        if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) {
            return 1;
        }
        if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) {
            return -1;
        }
        return 0;
    }
    /**
     *
     * Sorts the article array on article published
     *
     * sortPublished()
     */
    sortPublished() {
        this.pubOrder = !this.pubOrder;
        this.filteredArticles.sort((a, b) => {
            if (this.pubOrder) {
                if (a.published < b.published) {
                    return 1;
                }
                if (a.published > b.published) {
                    return -1;
                }
            }
            else {
                if (a.published < b.published) {
                    return -1;
                }
                if (a.published > b.published) {
                    return 1;
                }
            }
            return 0;
        });
    }
    /**
     *
     * This methods filters the article array and calls two filter methods: filterSearchList() and filterOnChannel()
     * This is needed so the filtered list can be filtered on search AND channels
     *
     * @param searchString
     *
     * FilterList()
     */
    // filterList(searchString: string) {
    //   let tempArticles = [];
    //   this.saveSearchString = searchString;
    //   tempArticles = this.filterSearchList(this.articles, this.saveSearchString);
    //   // console.log(this.articles);
    //   this.filteredArticles = this.filterOnChannel(this.selected, tempArticles);
    // }
    filterList(inSelected) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingFilter = true;
            this.articles$ = this.articleService.filterOnChannels(inSelected).pipe(map(articles => articles.sort(this.sortPublicationDefault)));
            this.loadingFilter = false;
        });
    }
    /**
     * This method filters checks if the string inputted by the users is equal to an article title in the array
     * If so it returns the searched article
     *
     * @param inArticles
     * @param searchString
     */
    filterSearchList(inArticles, searchString) {
        return inArticles.filter(article => {
            return article.title.toLowerCase().includes(searchString.toLowerCase());
        });
    }
    /**
     *
     * This method checks if the by user selected channel is present in the array of articles
     * If so, it returns the articles that are equal to this selection
     *
     * @param selectedOptions
     * @param inArticles
     *
     * filterOnChannel()
     */
    filterOnChannel(selectedOptions, inArticles) {
        // console.log(selectedOptions);
        const newArticles = [];
        if (selectedOptions.length <= 0) {
            return inArticles;
        }
        else {
            inArticles.forEach(article => {
                selectedOptions.forEach(option => {
                    if (article.channels.indexOf(option) >= 0) {
                        if (newArticles.indexOf(article) < 0) {
                            newArticles.push(article);
                        }
                    }
                });
            });
            return newArticles;
        }
    }
}


<mat-list *ngFor="let domain of this.domains; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="domainContainer">
    <div class="domainLine" (click)="editDomain(domain)">
      <div class="domain">{{domain.domain}}</div>
    </div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newDomain()" class="sticky-add-button" matTooltip="New Domain"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>
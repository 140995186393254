<mat-toolbar class="second-navbar">
    <div class="container" fxLayout="row" fxLayoutAlign="space-between">
        <button mat-icon-button (click)="toArticleList()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span>{{article.title}}</span>
        <button mat-icon-button [matMenuTriggerFor]="articleMenu" *ngIf="isEditor && mayEditByChannel | async">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div class="container" fxLayout="column">



    <div fxFlex="100" fxLayoutAlign="center center">
        <div class="article-content"> <span>
                <div *ngIf="channelDefs$|async as channelDefs"> <div>{{article.lastUpdateDateInMs|date}} | <span
                            *ngFor="let channel of article.channels; index as i">{{channel |channelDef: this.channelDefs }}
                            <span *ngIf="i !== article.channels.length-1">, </span> </span>

                </div></div>

                <h1 fxLayoutAlign="center center"><span>{{article.title}}</span> <button mat-icon-button
                        (click)="editArticle()">
                        <mat-icon matTooltip="Edit article">edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="article.published && isEditor">
                        <mat-icon matTooltip="This article is published">assignment_turned_in</mat-icon>
                    </button>

                </h1>
            </span></div>


    </div>
    <div fxFlex="100" style="width:100%">
        <div class="article-content">
            <div class="content fr-view" [froalaView]="article.content" style="width:100%"></div>
        </div>

    </div>


    <mat-divider></mat-divider>
    <div fxFlex="100" style="color:#9e9e9e" fxLayoutAlign="center center">
        <div>End of article</div>
        <span class="spacer"></span>
        <div class="last-edited" fxLayoutAlign="end center">
            <span>Viewed {{article.viewCount + 1}} times,&nbsp;</span>
            <span>Last edited: {{article.lastUpdateDateInMs | date:'dd-MM-yy HH:mm '}} by
                {{article.lastUpdatedBy}}</span></div>

    </div>

</div>



<mat-menu #articleMenu>
    <!-- Router navigate to edit component -->

    <button mat-menu-item (click)="editArticle()">Edit Article</button>

</mat-menu>
export const environment = {
    production: true,
name: 'acc',
    firebase: {
        apiKey: 'AIzaSyAHt6EcnFNIN8eCd0HuLegxsTiMuN0CVzI',
    authDomain: 'toppick-acc-icco.firebaseapp.com',
    databaseURL: 'https://toppick-acc-icco.firebaseio.com',
    projectId: 'toppick-acc-icco',
    storageBucket: 'toppick-acc-icco.appspot.com',
    messagingSenderId: '829522605849'
    }
  };

<div fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="100"><input type="file" accept="{{accept}}" (change)="setTarget($event.target.files)"></div> 
    <div fxFlex="100"><button mat-button type="submit" (click)="startUpload()">Upload</button></div>
    <div>
   
        <div *ngIf="percentage | async as pct">
  
            <mat-progress-bar 
                      [value]="pct" 
                      max="100">        
            </mat-progress-bar>
          
            {{ pct | number }}%
          
          </div>
  
    </div>
  </div>   
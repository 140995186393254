<div class="head-item-container">
    <div fxLayout="row" class="head-article-row" (click)="openArticle(headArticle)" fxHide.lt-sm>

        <div fxFlex="66" class="head-article-image"
            [ngStyle]="{'background-image':'url('+headArticle.mainImageRef+')'}"></div>
        <div fxFlex="33">
            <div fxLayout="column" fxLayoutAlign="space-between" class="head-article-text" style="height:100%">
                <div> <small>{{headArticle.lastUpdateDateInMs|date}} | <span
                            *ngFor="let channel of headArticle.channels; index as i">{{channel  | channelDef: this.channelDefs}}
                            <span *ngIf="i !== headArticle.channels.length-1">, </span> </span>
                        <span *ngIf="isEditor"> <button mat-icon-button
                                (click)="$event.stopPropagation(); editArticle(headArticle)"
                                *ngIf="(this.inEditor$|async|mayEdit: headArticle)">
                                <mat-icon matTooltip="Edit article">edit</mat-icon>
                            </button>
                            <button mat-icon-button *ngIf="headArticle.published">
                                <mat-icon matTooltip="This article is published">assignment_turned_in</mat-icon>
                            </button></span>

                    </small>
                    <h1>{{headArticle.title}}</h1>
                </div>

                <p>{{headArticle.summary}}</p>
                <p fxLayoutAlign="end center" class="read"><b>Read article</b></p>
            </div>
            <mat-divider></mat-divider>

        </div>


    </div>
    <div fxLayout="row" fxHide.gt-xs (click)="openArticle(headArticle)">
        <div fxFlex="100" class="head-article-image"
            [ngStyle]="{'background-image':'url('+headArticle.mainImageRef+')'}" fxLayoutAlign="end end">
            <div class="head-article-text-mobile">
                <small>{{headArticle.lastUpdateDateInMs|date}} | <span
                        *ngFor="let channel of headArticle.channels; index as i">{{channel  | channelDef: this.channelDefs}}
                        <span *ngIf="i !== headArticle.channels.length-1">, </span> </span></small>
                <h1>{{headArticle.title}}</h1>
            </div>
        </div>
    </div>

</div>
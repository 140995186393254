<div>
  <mat-list>
    <mat-list-item 
    style="width:100%" *ngFor="let allowedUser of allowedUserList; index as i" [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" >
    <span (click)="editAllowedUser(allowedUser.key)">{{allowedUser.email}}</span>
        <button type="button" mat-icon-button (click)="deleteUser(allowedUser.key)" ><mat-icon>delete</mat-icon></button>
    </mat-list-item>
  </mat-list> 

  <div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newAllowedUser()" class="sticky-add-button" matTooltip="New Channel"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>

</div>


import { Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, mergeMap } from 'rxjs/operators';
import { SettingsService } from '../services/settings.service';
import { RolesService } from '../services/roles.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/settings.service";
import * as i4 from "../services/roles.service";
export class AuthGuard {
    constructor(authService, router, settingsService, rolesService) {
        this.authService = authService;
        this.router = router;
        this.settingsService = settingsService;
        this.rolesService = rolesService;
    }
    /**
     * If the user is logged in and has a valid domain, the component may be 'activated'/shown
     * This method uses mergeMap:
     * this method merges the two mappings in the method to one answer (one boolean)
     *
     * @param next
     * @param state
     */
    canActivate(next, state) {
        return this.authService.getLoggedInUser().pipe(mergeMap(user => {
            if (user) {
                return this.rolesService.detectSysAdmin(user.email, '', '', '').pipe(mergeMap(isSysAdmin => {
                    if (isSysAdmin) {
                        return of(true);
                    }
                    else {
                        return this.authService.validateDomain(user).pipe(mergeMap((validatedDomain) => {
                            if (validatedDomain) {
                                return of(true);
                            }
                            else {
                                //       console.log('user validation');
                                return this.authService.validateAllowedUser(user.email).pipe(map(validatedUser => {
                                    if (validatedUser) {
                                        return true;
                                    }
                                    else {
                                        this.authService.signOut();
                                        this.router.navigate(['/logout']);
                                        return false;
                                    }
                                }));
                            }
                        }));
                    }
                }));
            }
            else {
                this.authService.googleLogin();
                return of(false);
            }
        }));
    }
    /**
     * Checks if the user has a valid email domain
     * @param user
     */
    validateDomain(user) {
        return this.settingsService.retrieveSupportedDomains().pipe(map(domainlist => {
            if (domainlist.some(x => user.email.indexOf(x.domain) >= 0)) {
                return true;
            }
            else {
                this.router.navigate(['/logout']);
                this.authService.signOut();
                return false;
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SettingsService), i0.ɵɵinject(i4.RolesService)); }, token: AuthGuard, providedIn: "root" });

<div class="container">


  <form (ngSubmit)="saveApikey()" #channelForm="ngForm">
    <div fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="name"><h3>Name</h3></label>
          <p class="helptext">Name of short description for the API key. Usually the name of the application that uses the key.</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="name" name="name" placeholder="API key name" [(ngModel)]="this.apikey.name">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="key"><h3>Key</h3></label>
          <p class="helptext">The API key. A unique string of at least 32 hexdecimal characters</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="key" name="key" style="width: 400px;" placeholder="API key" [(ngModel)]="this.apikey.apiKey">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="key"><h3>Push API URL</h3></label>
          <p class="helptext">Url to an external application where to push changes to</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="url" name="url" style="width: 400px;" placeholder="Url" [(ngModel)]="this.apikey.pushUrl">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="key"><h3>Push API Key</h3></label>
          <p class="helptext">Api key to use when pushing data to external Url. A unique string of at least 32 hexdecimal characters</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="pushkey" name="pushkey" style="width: 400px;" placeholder="Key" [(ngModel)]="this.apikey.pushKey">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="key"><h3>View article Url</h3></label>
          <p class="helptext">Url to this instance of TopPicks to view articles</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="viewurl" name="viewurl" style="width: 400px;" placeholder="View url" [(ngModel)]="this.apikey.viewUrl">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="key"><h3>Maximum number of articles per channel pushed</h3></label>
          <p class="helptext">When pushing changes to external application, what is the maximum number of aricles pushed per channel</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput type="number" id="maxpush" name="maxpush" style="width: 400px;" [(ngModel)]="this.apikey.pushArticleLimit">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <button mat-button type="button" (click)="HandleDelete()">Delete</button>
        <div style="width: 30px;"></div>
        <button mat-button type="button" (click)="handleCancel()">Cancel</button>
        <button mat-button type="submit" [disabled]="!channelForm.form.valid">Save</button>
      </div>

    </div>
  </form>
</div>

import { AngularFireDatabase } from '@angular/fire/database';
import { ArticleModel } from '../models/article-model';
import { ContentModel } from '../models/content-model';
import { combineLatest } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { ChannelLinkModel } from '../models/channel-link-model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class ArticleService {
    /**
     * ArticleService()
     *
     * @param {AngularFireDatabase} db
     */
    constructor(db) {
        this.db = db;
        this._articlesRef = db.list(ArticleService.articleNodeName);
        this._contentRef = db.list(ArticleService.contentNodeName);
        this._channelLinksRef = db.list(ArticleService.channelLinksNodeName);
    }
    /**
     * retrieveArticleList()
     *
     *
     * @returns {Observable<Array<ArticleModel>>}
     */
    retrieveArticleList() {
        return this._articlesRef.snapshotChanges().pipe(map(changes => changes.map(theArticleNode => (ArticleModel.fromFirebase(theArticleNode)))));
    }
    filterOnChannels(inChannelKeys) {
        if (inChannelKeys.length > 0) {
            return this.retrieveArticleList().pipe(map(articles => {
                const theArticles = [];
                articles.forEach(article => {
                    article.channels.forEach(articleChannel => {
                        if (inChannelKeys.some(channel => channel === articleChannel)) {
                            if (theArticles.indexOf(article) < 0) {
                                theArticles.push(article);
                            }
                        }
                    });
                });
                return theArticles;
            }));
        }
        return this.getArticlesFromIndex(null);
    }
    queryArticles(inQuery) {
        const articles$ = this.retrieveArticleList();
        const allContent$ = this.retrieveAllContent();
        return combineLatest(articles$, allContent$, (articles, allContent) => {
            const filteredArticles = articles.filter(article => this.filterArticlesOnTitle(inQuery, article) || this.filterArticlesOnSummary(inQuery, article) && article.published);
            const filteredContent = allContent.filter(theContent => this.filterOnContent(inQuery, theContent));
            articles.forEach(article => {
                if (filteredContent.some(content => content.key === article.contentKey)) {
                    if (filteredArticles.indexOf(article) < 0) {
                        filteredArticles.push(article);
                    }
                }
            });
            return filteredArticles;
        });
        // return this.retrieveArticleList().pipe(
        //   map(articles => {
        //     const content: string[] = [];
        //     articles.forEach(article => {
        //     })
        //     const filteredArticles = articles.filter(
        //       article => this.filterArticlesOnTitle(inQuery, article) || this.filterArticlesOnSummary(inQuery, article));
        //     return null;
        //     //TODO: sort articles
        //   })
        // )
    }
    filterOnContent(inQuery, inContent) {
        return inContent.content.toLowerCase().includes(inQuery.toLowerCase());
    }
    filterArticlesOnTitle(inQuery, inArticle) {
        return inArticle.title.toLowerCase().includes(inQuery.toLowerCase());
    }
    filterArticlesOnSummary(inQuery, inArticle) {
        return inArticle.summary.toLowerCase().includes(inQuery.toLowerCase());
    }
    getArticlesFromIndex(inArticle) {
        if (inArticle) {
            // return this.db.list('articles', ref=> ref.orderByChild('lastUpdateInMs', ).startAt(inArticle.key)).snapshotChanges().pipe(
            return this.db.list('articles', ref => ref.orderByKey().endAt(inArticle.key).limitToLast(11)).snapshotChanges().pipe(map(changes => changes.map(theArticleNode => (ArticleModel.fromFirebase(theArticleNode))).sort(this.sortArticles)));
        }
        //inStartArticle
        return this.db.list('articles', ref => ref.limitToLast(15)).snapshotChanges().pipe(map(changes => changes.map(theArticleNode => (ArticleModel.fromFirebase(theArticleNode))).sort(this.sortArticles)));
    }
    searchArticle(inQuery) {
        return this.db.list('articles', ref => ref.orderByChild('title').startAt(inQuery).endAt(inQuery + "\uf8ff").limitToLast(10)).snapshotChanges().pipe(map(changes => changes.map(theArticleNode => (ArticleModel.fromFirebase(theArticleNode)))));
    }
    sortArticles(a, b) {
        if (a.lastUpdateDateInMs < b.lastUpdateDateInMs) {
            return -1;
        }
        if (a.lastUpdateDateInMs > b.lastUpdateDateInMs) {
            return 1;
        }
        return 0;
        // return dateA.getTime() - dateB.getTime();
    }
    /**
     * retrieveArticle()
     *
     * Returns an article with inKey from the database
     *
     * @param {string} inKey
     * @returns {ArticleModel}
     */
    retrieveArticle(inKey) {
        // Create a new promise
        return new Promise((resolve) => {
            if (inKey && inKey.length > 0) {
                // Retrieve the data
                const theSubscription = this.db.object(ArticleService.articleNodeName + '/' + inKey).snapshotChanges().subscribe(inData => {
                    // Parse the data
                    const theArticle = ArticleModel.fromFirebase(inData);
                    // Make sure to unsubscribe
                    theSubscription.unsubscribe();
                    // Collect the content if available
                    if (theArticle.contentKey.length > 0) {
                        this._retrieveContent(theArticle.contentKey).then(theContent => {
                            // Copy the content
                            theArticle.content = theContent.content;
                            theArticle.viewCount = theContent.viewCount;
                            // And resolve the promise
                            resolve(theArticle);
                        });
                    }
                    else {
                        // And resolve the promise
                        resolve(theArticle);
                    }
                });
            }
            else {
                // Empty key received, return a new article
                const theArticle = new ArticleModel();
                resolve(theArticle);
            }
        });
    }
    retrieveArticleObservable(inKey) {
        return this.db.object(ArticleService.articleNodeName + '/' + inKey).snapshotChanges().pipe(map(result => {
            return ArticleModel.fromFirebase(result);
        })
        // TODO: retreive articlecontent 
        );
    }
    /**
     * storeArticle()
     *
     * Stores an article in the fireBase database. Will update the existing
     * article if a key is present, will create a new article if the key is empty
     *
     * @param {ArticleModel} inArticle                    The article to be saved
     * @param {Array<ChannelDefModel>} inChannelDefs      The list of all known channels
     * @param {string} inEditorEmail                      The email address of the user/editor who is storing the article
     */
    storeArticle(inArticle, inChannelDefs, inEditorEmail) {
        // Check if a key is present.
        if (inArticle.hasKey()) {
            // A key is present, so update the existing article
            // Store the content
            this._storeContent(inArticle.contentKey, inArticle.content);
            // Update the existing article
            inArticle.lastUpdateDateInMs = new Date().getTime();
            inArticle.lastUpdatedBy = inEditorEmail;
            this._articlesRef.update(inArticle.key, inArticle.toFirebase());
            // Update the channel links
            this._updateChannelLinks(inArticle, inChannelDefs);
        }
        else {
            // No key is present, so this is a new article. Push it to fireBase
            // Store the content
            this._storeContent(inArticle.contentKey, inArticle.content).then(theContent => {
                // Add the contentKey to the article
                inArticle.contentKey = theContent.key;
                // Store the article
                inArticle.lastUpdateDateInMs = new Date().getTime();
                inArticle.lastUpdatedBy = inEditorEmail;
                inArticle.creationDateInMs = new Date().getTime();
                inArticle.createdBy = inEditorEmail;
                this._articlesRef.push(inArticle.toFirebase()).then((theArticle) => {
                    // Add the generated key to the model.
                    inArticle.key = theArticle.key;
                    // Update the channel links
                    this._updateChannelLinks(inArticle, inChannelDefs);
                });
            });
        }
    }
    /**
     * removeArticle()
     *
     * Removes the article inArticle and the connected content
     *
     * @param {ArticleModel} inArticle
     */
    removeArticle(inArticle) {
        // Check if a key is present.
        if (inArticle.hasKey()) {
            if (inArticle.contentKey.length > 0) {
                this._removeContent(inArticle.contentKey);
            }
            this._articlesRef.remove(inArticle.key).then();
        }
    }
    retrieveAllContent() {
        return this.db.list(ArticleService.contentNodeName).snapshotChanges().pipe(map(results => {
            return results.map(result => {
                return ContentModel.fromFirebase(result);
            });
        }));
    }
    /**
     * _retrieveContent()
     *
     * Returns a content with inKey from the database
     *
     * @param {string} inKey
     * @returns {Promise<ContentModel>}
     * @private
     */
    _retrieveContent(inKey) {
        // Create a new promise
        // return new Promise<ContentModel>((resolve) => {
        // Retrieve the data
        return this.db.object(ArticleService.contentNodeName + '/' + inKey).snapshotChanges().pipe(map(result => {
            return ContentModel.fromFirebase(result);
        }), first()).toPromise();
        //   const theSubscription = this.db.object(ArticleService.contentNodeName + '/' + inKey).snapshotChanges().subscribe(
        //     inData => {
        //       const theContent = ContentModel.fromFirebase(inData);
        //       // Make sure to unsubscribe
        //       theSubscription.unsubscribe();
        //       resolve(theContent);
        //     });
        // });
    }
    // private _retrieveContent$(inKey: string): Observable<ContentModel> {
    // }
    /**
     * _storeContent()
     *
     * Updates the content of the article, creating a new content node of the content.key
     * is empty, updates the existing content node if the key is present.
     *
     * @param {string} inContentKey     string      The current key for the content
     * @param {string} inContent        string      The content itself
     *
     * @returns {Promise<ContentModel>}   Returns the complete content, including generated key
     * @private
     */
    _storeContent(inContentKey, inContent) {
        const theContent = new ContentModel();
        theContent.key = inContentKey;
        theContent.content = inContent;
        if (theContent.hasKey()) {
            // Create the promise
            return new Promise((resolve) => {
                // Update the content
                this._contentRef.update(theContent.key, theContent.toFirebase()).then(() => {
                    resolve(theContent);
                });
            });
        }
        else {
            // Create the promise
            return new Promise((resolve) => {
                // create new content
                this._contentRef.push(theContent.toFirebase()).then(theStoredContent => {
                    theContent.key = theStoredContent.key;
                    resolve(theContent);
                });
            });
        }
    }
    /**
     * incViewCount()
     *
     * Increments the viewcount for the article
     * Should be called whenever someone views (not edits) the article
     *
     * @param inContentKey
     */
    incViewCount(inContentKey) {
        this.db.object(ArticleService.contentNodeName + `/${inContentKey}/viewCount`)
            .query.ref.transaction(viewCount => {
            if (viewCount === null) {
                return viewCount = 1;
            }
            else {
                return viewCount + 1;
            }
        }).then();
    }
    /**
     * _removeContent()
     *
     * Removes the content node with key inContentKey
     *
     * @param {string} inContentKey
     * @private
     */
    _removeContent(inContentKey) {
        if (inContentKey.length > 0) {
            this._contentRef.remove(inContentKey);
        }
    }
    /**
     * _updateChannelLinks()
     *
     * This updates the channelLink list from the current state of the Article
     * This should be called whenever an Article is stored.
     *
     * If the article is NOT published, remove any existing links
     *
     * @param {ArticleModel} inArticle
     * @param {Array<ChannelDefModel>} inChannelDefs
     * @private
     */
    _updateChannelLinks(inArticle, inChannelDefs) {
        // collect all channelLinks for this article
        // Create the query
        const theChannelLinksRef = this.db.list(ArticleService.channelLinksNodeName, ref => ref.orderByChild('articleKey').equalTo(inArticle.key));
        // Collect the data
        const theChannelLink$ = theChannelLinksRef.snapshotChanges().pipe(map(changes => changes.map(theChannelLinkNode => (ChannelLinkModel.fromFirebase(theChannelLinkNode)))));
        const theLinks$ = theChannelLink$.subscribe((inChannelLinks) => {
            if (inArticle.published) {
                // Remove any channelDefs that are (no longer) present
                inChannelLinks.forEach((inChannelLink) => {
                    // Is this link still present (is the channelDef.key present in the articleModel.channels
                    if (inArticle.channels.indexOf(inChannelLink.channelDefKey) < 0) {
                        // Remove it
                        this._channelLinksRef.remove(inChannelLink.key);
                    }
                });
                // Add and channelDefs that are not yet present
                inArticle.channels.forEach((inChannelDefKey) => {
                    let isLinkPresent = false;
                    // Try to find the link
                    inChannelLinks.forEach((inChannelLink) => {
                        if (inChannelLink.channelDefKey === inChannelDefKey) {
                            isLinkPresent = true;
                        }
                    });
                    // If this channelDefKey is NOT present, add it
                    if (!isLinkPresent) {
                        const theNewLink = new ChannelLinkModel();
                        theNewLink.channelDefKey = inChannelDefKey;
                        theNewLink.articleKey = inArticle.key;
                        this._channelLinksRef.push(theNewLink.toFirebase());
                    }
                });
            }
            else {
                // Remove all channel links
                inChannelLinks.forEach((inChannelLink) => {
                    // Remove it
                    this._channelLinksRef.remove(inChannelLink.key);
                });
            }
            theLinks$.unsubscribe();
        });
    }
}
// node name definitions
ArticleService.articleNodeName = '/articles';
ArticleService.contentNodeName = '/content';
ArticleService.channelLinksNodeName = '/channelLinks';
ArticleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArticleService_Factory() { return new ArticleService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: ArticleService, providedIn: "root" });

import { AngularFireDatabase } from '@angular/fire/database';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class CloudTriggerService {
    constructor(db) {
        this.db = db;
        this._triggersRef = db.list(CloudTriggerService.cloudTriggersNodeName);
    }
    addTrigger(inTrigger) {
        // Create a new node
        this._triggersRef.push(inTrigger.toFirebase());
    }
}
// Defines actions
CloudTriggerService.sysadmininit = 'sysadmininit'; // Cloud function that will initialize the sysadmins
// node name definitions
CloudTriggerService.cloudTriggersNodeName = 'cloudTriggers'; // Node name of the Cloud Triggers node in fireBase
CloudTriggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CloudTriggerService_Factory() { return new CloudTriggerService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: CloudTriggerService, providedIn: "root" });

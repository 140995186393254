<!--The content below is only a placeholder and can be replaced.-->

<app-navbar (mDrawerEmitter)=sidenav.toggle()></app-navbar>

<mat-sidenav-container style="height: 91vh;" [hasBackdrop]="true">
  <mat-sidenav #sidenav mode="push" style="width:200px" (click)="sidenav.toggle()"><app-sidenav></app-sidenav></mat-sidenav>

  <mat-sidenav-content cdkScrollable>

    <main> <router-outlet></router-outlet></main>
     
  </mat-sidenav-content>


</mat-sidenav-container>
<footer></footer>
 
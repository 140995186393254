<div class="header">
<mat-toolbar>
  <nav>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" >

  
     
        <button mat-icon-button (click)="openDrawer()">
          <mat-icon class="hamburger">menu</mat-icon>
        </button>
      
  <div *ngIf="!search;else searchBar">          <a class="logo" *ngIf="logoUrl | async; let backgroundUrl" href="/" fxLayoutAlign="start center"><img  class="logo" src="{{backgroundUrl}}"></a>     
</div>

<ng-template #searchBar>
  <div class="search" >
    <app-article-search (itemClicked)="toggleSearch()"></app-article-search>
  </div>
</ng-template>


      
     <div fxLayout="row">
       <button mat-icon-button (click)="toggleSearch()" *ngIf="!search;else closeButton"><mat-icon>search</mat-icon></button>  
       <ng-template #closeButton>
        <button mat-icon-button (click)="toggleSearch()" ><mat-icon>clear</mat-icon></button>  

      </ng-template>
   
      <img class="user-profile-pic "*ngIf="user" src="{{user.photoURL}}"></div>
      

     
    </div>
    

  </nav>
</mat-toolbar>
</div>

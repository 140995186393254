/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fileupload.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "./fileupload.component";
import * as i13 from "../../../services/file.service";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["max", "100"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(2, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", "% "])), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_3 = _v.context.ngIf; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.ngIf)); _ck(_v, 3, 0, currVal_4); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["fxLayout", "column"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 1720320, null, 0, i6.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i8.Directionality, i7.StyleUtils, [2, i6.LayoutGapStyleBuilder], i7.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i6.DefaultFlexDirective, [i1.ElementRef, i7.StyleUtils, i7.LAYOUT_CONFIG, i6.FlexStyleBuilder, i7.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["type", "file"]], [[8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setTarget($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(8, 671744, null, 0, i6.DefaultFlexDirective, [i1.ElementRef, i7.StyleUtils, i7.LAYOUT_CONFIG, i6.FlexStyleBuilder, i7.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""], ["type", "submit"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Upload"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FileUploadComponent_1)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "100"; _ck(_v, 5, 0, currVal_2); var currVal_4 = "100"; _ck(_v, 8, 0, currVal_4); var currVal_7 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.percentage)); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.accept, ""); _ck(_v, 6, 0, currVal_3); var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_5, currVal_6); }); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fileupload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵdid(1, 114688, null, 0, i12.FileUploadComponent, [i13.FileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-fileupload", i12.FileUploadComponent, View_FileUploadComponent_Host_0, { filePath: "filePath", accept: "accept" }, {}, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };

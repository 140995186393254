import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { SettingsService } from './settings.service';
import { map } from '../../../node_modules/rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/router";
import * as i3 from "./settings.service";
export class AuthService {
    constructor(afAuth, router, settingsService) {
        this.afAuth = afAuth;
        this.router = router;
        this.settingsService = settingsService;
        this.authState = afAuth.auth;
    }
    /**
     * googleLogin()
     *
     * This method can be used to sign in with Google via a redirect
     */
    googleLogin() {
        return this.authState.signInWithRedirect(new firebase.auth.GoogleAuthProvider);
    }
    /**
     * getLoggedInUser()
     *
     * Get the logged in user in Firebase
     */
    getLoggedInUser() {
        return this.afAuth.authState;
    }
    /**
     * signOut()
     *
     * Signs out the user
     */
    signOut() {
        this.afAuth.auth.signOut().then(() => {
            this.router.navigate(['/logout']);
        });
    }
    validateDomain(user) {
        return this.settingsService.retrieveSupportedDomains().pipe(map(domainlist => {
            if (domainlist.some(x => user.email.indexOf(x.domain) >= 0)) {
                return true;
            }
            else {
                return false;
            }
        }));
    }
    validateAllowedUser(inEmail) {
        return this.settingsService.getAllowedUsers().pipe(map(allowedUsers => {
            if (allowedUsers.some((emailobj) => inEmail.indexOf(emailobj.email) >= 0)) {
                return true;
            }
            else {
                return false;
            }
        }));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SettingsService)); }, token: AuthService, providedIn: "root" });

import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { ApiKeyModel } from '../models/api-key-model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class ApiKeysService {
    /**
     * constructor
     *
     * @param {AngularFireDatabase} db
     */
    constructor(db) {
        this.db = db;
        // Create a reference for the apiKey definitions
        this._apiKeysRef = db.list(ApiKeysService.apiKeyNodeName);
    }
    /**
     * retrieveApiKey()
     *
     *
     * @param {string} inKey
     * @returns {Promise<ApiKeyModel>}
     */
    retrieveApiKey(inKey) {
        // Create a new promise
        const thePromise = new Promise((resolve, reject) => {
            if (inKey && inKey.length > 0) {
                // Retrieve the data
                const theSubscription = this.db.object(ApiKeysService.apiKeyNodeName + '/' + inKey).snapshotChanges().subscribe(inData => {
                    // Parse the data
                    const theApikey = ApiKeyModel.fromFirebase(inData);
                    // Make sure to unsubscribe
                    theSubscription.unsubscribe();
                    // And resolve the promise
                    resolve(theApikey);
                });
            }
            else {
                const theApikey = new ApiKeyModel();
                resolve(theApikey);
            }
        });
        return thePromise;
    }
    /**
     * retrieveApiKeys()
     *
     * Returns an Observable to the array with ApiKeyModels
     *
     * @returns {Observable<Array<ApiKeyModel>>}
     */
    retrieveApiKeys() {
        return this._apiKeysRef.snapshotChanges().pipe(map(changes => changes.map(apiKeyDefNode => (ApiKeyModel.fromFirebase(apiKeyDefNode)))));
    }
    /**
     * storeApiKey()
     *
     * Stores a channelDef in the fireBase database. Will update the existing
     * channelDef if a key is present, will create a new channelDef if the key is empty
     *
     * @param {ApiKeyModel} inApiKey
     */
    storeApiKey(inApiKey) {
        // Is it an existing defintions or a new one
        if (inApiKey.hasKey()) {
            // Update the existing node
            this._apiKeysRef.update(inApiKey.key, inApiKey.toFirebase());
        }
        else {
            // Create a new node
            this._apiKeysRef.push(inApiKey.toFirebase());
        }
    }
    /**
     * removeApiKey()
     *
     * Removes an apiKey from the database
     *
     * @param {ApiKeyModel} inApiKey
     */
    removeApiKey(inApiKey) {
        if (inApiKey.hasKey()) {
            this._apiKeysRef.remove(inApiKey.key);
        }
    }
}
// node name definitions
ApiKeysService.apiKeyNodeName = 'apikeys';
ApiKeysService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiKeysService_Factory() { return new ApiKeysService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: ApiKeysService, providedIn: "root" });

<div class="container">


  <form (ngSubmit)="saveEditor()" #editorForm="ngForm">
  <div fxLayout="column" fxLayoutGap="20px">

    <div fxLayout="column">
      <div fxFlex="100">
        <label for="email"><h3>Editors email address</h3></label>
      </div>
      <div fxFlex="100">
        <mat-form-field class="full-width">
          <!--<input matInput placeholder="Editor email" [(ngModel)]="this.sysadmin.email">-->

          <input id="name" name="name"
                 matInput placeholder="Email" [formControl]="emailFormControl"
                 [errorStateMatcher]="matcher" [(ngModel)]="this.editor.email">
          <mat-hint></mat-hint>
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column">
      <div fxFlex="100">
        <label for="allchannels"><h3>Channels available for editor</h3></label>
      </div>
        <mat-checkbox id="allchannels" name="allchannels" [(ngModel)]="editor.allChannels">All channels</mat-checkbox>
    </div>

    <div fxLayout="column">
      <div fxFlex="100">
        <label for="channels"><h3>Channel selection</h3></label>
      </div>
      <div fxFlex="100">
        <mat-form-field style="width:100%">
          <mat-chip-list #editorChannelList>
            <mat-chip *ngFor="let editorChannel of editor.channels">{{editorChannel | channelDef: this.channelDefs}}
              <mat-icon matChipRemove (click)="removeFromEditorChannels(editorChannel)">cancel</mat-icon>
            </mat-chip>
            <input editorChannelInput name="channels" matInput [matAutocomplete]="auto" [matChipInputFor]="editorChannelList">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let channel of this.channelDefs" (click)="addToEditorChannels(channel)">{{channel.name}}

              </mat-option>
            </mat-autocomplete>

          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
      <button mat-button type="button" (click)="HandleDelete()">Delete</button>
      <div style="width: 30px;"></div>
      <button mat-button type="button" (click)="handleCancel()">Cancel</button>
      <button mat-button type="submit" [disabled]="emailFormControl.invalid">Save</button>
    </div>
  </div>
  </form>
  </div>

<div>
    <form (ngSubmit)="searchArticle()" #articleForm="ngForm">
        <div fxLayout="row" fxLayoutAlign="start center">
            
<mat-form-field style="width: 100%;" floatLabel="never">
    <mat-label>Press enter or the search icon to start searching articles</mat-label>
    <input matInput type="text"  [matAutocomplete]="auto" [(ngModel)]="this.query" name="query" id="query">
    <button mat-icon-button matSuffix type="submit" ><mat-icon>search</mat-icon></button>

    <mat-autocomplete #auto="matAutocomplete">
        <div *ngIf="searchedArticles">
          <mat-option *ngFor="let article of searchedArticles|slice:0:10" [value]="article.title" (click)="openArticle(article); onItemClick(article)">
            <span>{{article.title}} - </span><span>{{article.lastUpdateDateInMs | date}}</span>
           
          </mat-option>
    
        </div>
    
      </mat-autocomplete>
</mat-form-field>
<mat-spinner *ngIf="queryLoading" [diameter]="20"></mat-spinner>
</div>
</form>
</div> 


import { AngularFireDatabase } from '@angular/fire/database';
import { ChannelDefModel } from '../models/settings/channel-def-model';
import { map } from 'rxjs/operators';
import { DomainDefModel } from '../models/settings/domain-def-model';
import { AllowedUserModel } from '../models/allowed-user-model';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
import * as i2 from "@angular/fire/storage";
export class SettingsService {
    /**
     * constructor
     *
     * @param {AngularFireDatabase} db
     */
    constructor(db, afStorage) {
        this.db = db;
        this.afStorage = afStorage;
        // Create a reference for the channel definitions
        this._channelDefsRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.channelDefsNodeName);
        // Create a reference for the supported Domains
        this.__supportedDomainsRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.supportedDomainsNodeName);
        // Create a reference for the allowed users
        this.allowedUsersRef = db.list(SettingsService.settingsNodeName + '/' + SettingsService.invitesNodeName);
    }
    /**
     * retrieveChannelDefs()
     *
     * Returns an Observable to the array with ChannelDefModels
     *
     * @returns {Observable<Array<ChannelDefModel>>}
     */
    retrieveChannelDefs() {
        return this._channelDefsRef.snapshotChanges().pipe(map((changes) => changes.map(channelDefNode => (ChannelDefModel.fromFirebase(channelDefNode))).sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
            }
            return 0;
        })));
    }
    retrieveChannelDef(inKey) {
        // Create a new promise
        const thePromise = new Promise((resolve, reject) => {
            if (inKey && inKey.length > 0) {
                // Retrieve the data
                const theSubscription = this.db.object(SettingsService.settingsNodeName + '/' + SettingsService.channelDefsNodeName + '/' + inKey).snapshotChanges().subscribe(inData => {
                    // Parse the data
                    const theChannel = ChannelDefModel.fromFirebase(inData);
                    // Make sure to unsubscribe
                    theSubscription.unsubscribe();
                    // And resolve the promise
                    resolve(theChannel);
                });
            }
            else {
                // Empty key received, return a new channel
                const theChannel = new ChannelDefModel();
                resolve(theChannel);
            }
        });
        return thePromise;
    }
    /**
     * storeChannelDef()
     *
     * Stores a channelDef in the fireBase database. Will update the existing
     * channelDef if a key is present, will create a new channelDef if the key is empty
     *
     * @param {ChannelDefModel} inChannelDef
     */
    storeChannelDef(inChannelDef) {
        // Is it an existing defintions or a new one
        if (inChannelDef.hasKey()) {
            // Update the existing node
            this._channelDefsRef.update(inChannelDef.key, inChannelDef.toFirebase());
        }
        else {
            // Create a new node
            this._channelDefsRef.push(inChannelDef.toFirebase());
        }
    }
    /**
     * removeChannelDef()
     *
     * Removes a channelDef from the database
     *
     * @param {ChannelDefModel} inChannelDef
     */
    removeChannelDef(inChannelDef) {
        if (inChannelDef.hasKey()) {
            this._channelDefsRef.remove(inChannelDef.key);
        }
    }
    /**
     * retrieveSupportedDomains()
     *
     * Returns an Observable to the array with DomainDefModel
     *
     * @returns {Observable<Array<DomainDefModel>>}
     */
    retrieveSupportedDomains() {
        return this.__supportedDomainsRef.snapshotChanges().pipe(map(changes => changes.map(domainNode => (DomainDefModel.fromFirebase(domainNode)))));
    }
    /**
     * retrieveSupportedDomain()
     *
     *
     *
     * @param {string} inKey
     * @returns {Promise<DomainDefModel>}
     */
    retrieveSupportedDomain(inKey) {
        const thePromise = new Promise((resolve, reject) => {
            if (inKey && inKey.length > 0) {
                // Retrieve the data
                const theSubscription = this.db.object(SettingsService.settingsNodeName + '/' + SettingsService.supportedDomainsNodeName + '/' + inKey).snapshotChanges().subscribe(inData => {
                    // Parse the data
                    const theDomain = DomainDefModel.fromFirebase(inData);
                    // Make sure to unsubscribe
                    theSubscription.unsubscribe();
                    // And resolve the promise
                    resolve(theDomain);
                });
            }
            else {
                const theDomain = new DomainDefModel();
                resolve(theDomain);
            }
        });
        return thePromise;
    }
    /**
     * storeDomain()
     *
     * Stores a channelDef in the fireBase database. Will update the existing
     * channelDef if a key is present, will create a new channelDef if the key is empty
     *
     * @param {DomainDefModel} inDomainDef
     */
    storeDomain(inDomainDef) {
        // Is it an existing defintions or a new one
        if (inDomainDef.hasKey()) {
            // Update the existing node
            this.__supportedDomainsRef.update(inDomainDef.key, inDomainDef.toFirebase());
        }
        else {
            // Create a new node
            this.__supportedDomainsRef.push(inDomainDef.toFirebase());
        }
    }
    /**
     * removeDomain()
     *
     * Removes a domainDef from the database
     *
     * @param {DomainDefModel} inDomainDef
     */
    removeDomain(inDomainDef) {
        if (inDomainDef.hasKey()) {
            this.__supportedDomainsRef.remove(inDomainDef.key);
        }
    }
    addAllowedUser(inObj) {
        if (inObj.key !== '' && inObj.key !== null) {
            this.allowedUsersRef.update(inObj.key, inObj.toFirebase());
        }
        else {
            this.allowedUsersRef.push(inObj.toFirebase());
        }
    }
    getAllowedUsers() {
        return this.allowedUsersRef.snapshotChanges().pipe(map(allowedUsersList => {
            return allowedUsersList.map(userObj => {
                return AllowedUserModel.fromFirebase(userObj);
            });
        }));
    }
    getAllowedUserByKey(inKey) {
        return this.db.object('settings/allowedUsers/' + inKey).snapshotChanges().pipe(map(result => {
            return AllowedUserModel.fromFirebase(result);
        }));
    }
    deleteAllowedUser(inKey) {
        if (inKey) {
            this.allowedUsersRef.remove(inKey);
        }
    }
    getLogo() {
        return this.afStorage.ref('images/logo/logo.png').getDownloadURL();
    }
}
// node name definitions
SettingsService.settingsNodeName = 'settings';
SettingsService.channelDefsNodeName = 'channelDefs';
SettingsService.supportedDomainsNodeName = 'supportedDomains';
SettingsService.invitesNodeName = 'allowedUsers';
SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.AngularFireDatabase), i0.ɵɵinject(i2.AngularFireStorage)); }, token: SettingsService, providedIn: "root" });

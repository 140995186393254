/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./apikey-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "@angular/material/tooltip";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "@angular/cdk/scrolling";
import * as i14 from "@angular/cdk/platform";
import * as i15 from "@angular/cdk/bidi";
import * as i16 from "@angular/platform-browser";
import * as i17 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i18 from "@angular/material/icon";
import * as i19 from "./apikey-list.component";
import * as i20 from "../../../services/roles.service";
import * as i21 from "@angular/router";
import * as i22 from "../../../services/api-keys.service";
var styles_ApikeyListComponent = [i0.styles];
var RenderType_ApikeyListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApikeyListComponent, data: {} });
export { RenderType_ApikeyListComponent as RenderType_ApikeyListComponent };
function View_ApikeyListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(1, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "mat-list-item", [["class", "apikeyLine mat-list-item"]], [[2, "odd", null], [2, "even", null], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(3, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵeld(7, 0, null, 2, 1, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editApikey(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", " : ", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.index % 2) === 0); var currVal_1 = ((_v.context.index % 2) !== 0); var currVal_2 = (i1.ɵnov(_v, 3)._avatar || i1.ɵnov(_v, 3)._icon); var currVal_3 = (i1.ɵnov(_v, 3)._avatar || i1.ɵnov(_v, 3)._icon); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.name; var currVal_5 = _v.context.$implicit.apiKey; _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_ApikeyListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApikeyListComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["fxLayout", "column"], ["fxLayoutAlign", "end end"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "sticky-add-container"], ["fxFlex", "100"], ["fxLayoutAlign", "end center"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(7, 671744, null, 0, i5.DefaultFlexDirective, [i1.ElementRef, i6.StyleUtils, i6.LAYOUT_CONFIG, i5.FlexStyleBuilder, i6.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 5, "button", [["class", "sticky-add-button"], ["mat-fab", ""], ["matTooltip", "New Api Key"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.newApikey() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 212992, null, 0, i11.MatTooltip, [i12.Overlay, i1.ElementRef, i13.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i14.Platform, i9.AriaDescriber, i9.FocusMonitor, i11.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i15.Directionality], [2, i11.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i16.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i17.View_MatIcon_0, i17.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i18.MatIcon, [i1.ElementRef, i18.MatIconRegistry, [8, null], [2, i18.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.apikeys; _ck(_v, 1, 0, currVal_0); var currVal_1 = "column"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "end end"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "end center"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "100"; _ck(_v, 7, 0, currVal_4); var currVal_7 = "New Api Key"; _ck(_v, 10, 0, currVal_7); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_5 = (i1.ɵnov(_v, 9).disabled || null); var currVal_6 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 12).inline; var currVal_9 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_8, currVal_9); }); }
export function View_ApikeyListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-apikey-list", [], null, null, null, View_ApikeyListComponent_0, RenderType_ApikeyListComponent)), i1.ɵdid(1, 114688, null, 0, i19.ApikeyListComponent, [i20.RolesService, i21.Router, i22.ApiKeysService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApikeyListComponentNgFactory = i1.ɵccf("app-apikey-list", i19.ApikeyListComponent, View_ApikeyListComponent_Host_0, {}, {}, []);
export { ApikeyListComponentNgFactory as ApikeyListComponentNgFactory };

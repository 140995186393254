/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidenav.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "./sidenav.component";
import * as i6 from "../../services/roles.service";
import * as i7 from "@angular/router";
var styles_SidenavComponent = [i0.styles];
var RenderType_SidenavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidenavComponent, data: {} });
export { RenderType_SidenavComponent as RenderType_SidenavComponent };
function View_SidenavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.newArticle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 4, { _lines: 1 }), i1.ɵqud(603979776, 5, { _avatar: 0 }), i1.ɵqud(603979776, 6, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["New article"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SidenavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toAdmin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 7, { _lines: 1 }), i1.ɵqud(603979776, 8, { _avatar: 0 }), i1.ɵqud(603979776, 9, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Admin"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SidenavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toSysAdmin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 10, { _lines: 1 }), i1.ɵqud(603979776, 11, { _avatar: 0 }), i1.ɵqud(603979776, 12, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["SysAdmin"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SidenavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "container sidenav-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(2, 704512, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toArticleList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(4, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(603979776, 2, { _avatar: 0 }), i1.ɵqud(603979776, 3, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Article list"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SidenavComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SidenavComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SidenavComponent_3)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.rolesService.isEditor; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.rolesService.isAdmin; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.rolesService.isSysAdmin; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4)._avatar || i1.ɵnov(_v, 4)._icon); var currVal_1 = (i1.ɵnov(_v, 4)._avatar || i1.ɵnov(_v, 4)._icon); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_SidenavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidenav", [], null, null, null, View_SidenavComponent_0, RenderType_SidenavComponent)), i1.ɵdid(1, 114688, null, 0, i5.SidenavComponent, [i6.RolesService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidenavComponentNgFactory = i1.ɵccf("app-sidenav", i5.SidenavComponent, View_SidenavComponent_Host_0, {}, {}, []);
export { SidenavComponentNgFactory as SidenavComponentNgFactory };

<div class="other-articles-container">
    <div fxLayout="row" class="other-articles-row" fxLayoutGap="10px">
        <div fxFlex="33" *ngFor="let article of articles|slice:1:4" class="pointer hover other-articles-item"
            (click)="openArticle(article)">
            <div class="other-articles-images" [ngStyle]="{'background-image':'url('+article.mainImageRef+')'}">

            </div>
            <div class="other-articles-content">

                <small>{{article.lastUpdateDateInMs|date}} |
                    <span
                        *ngFor="let channel of article.channels; index as i">{{channel  | channelDef: this.channelDefs}}
                        <span *ngIf="i !== article.channels.length-1">, </span> </span>
                    <span *ngIf="isEditor">
                        <button mat-icon-button *ngIf="(this.inEditor$|async|mayEdit: article)"
                            (click)="$event.stopPropagation(); editArticle(article)">
                            <mat-icon matTooltip="Edit article">edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="article.published">
                            <mat-icon matTooltip="This article is published">assignment_turned_in</mat-icon>
                        </button></span>


                </small>


                <div>
                    <h2>{{article.title}}</h2>
                </div>
                <div>
                    <p class="article-summary"><small>{{article.summary}}</small></p>
                </div>
                <!-- <div fxLayoutAlign="end">
                    <p><small><b>Read</b></small></p>
                </div> -->
                <mat-divider></mat-divider>
            </div>


        </div>

    </div>
</div>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, EventEmitter } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
export class ArticleSearchComponent {
    constructor(articleService, router) {
        this.articleService = articleService;
        this.router = router;
        this.query = '';
        this.queryLoading = false;
        this.itemClicked = new EventEmitter();
    }
    ngOnInit() {
    }
    searchArticle() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.queryLoading = true;
                this.searchedArticles = yield this.articleService.queryArticles(this.query).pipe(first()).toPromise();
            }
            catch (error) {
            }
            finally {
                this.queryLoading = false;
            }
        });
    }
    openArticle(inArticle) {
        this.router.navigate(['/articles/view', inArticle.key]);
    }
    onItemClick(inArticle) {
        this.itemClicked.emit(inArticle);
    }
}

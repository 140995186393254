
<mat-list *ngFor="let channel of this.channels; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="channelLine">
    <div (click)="editChannel(channel)">{{channel.name}}</div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newChannel()" class="sticky-add-button" matTooltip="New Channel"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>
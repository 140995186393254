<div class="container">

  <mat-tab-group dynamicHeight>
    <mat-tab label="Sys Admins">
      <app-sysadmin-list></app-sysadmin-list>
    </mat-tab>
    <mat-tab label="Admins">
      <app-admin-list></app-admin-list>
    </mat-tab>
    <mat-tab label="Api keys">
    <app-apikey-list></app-apikey-list>
  </mat-tab>
    <mat-tab label="Domains">
      <app-domain-list></app-domain-list>
    </mat-tab>
    <mat-tab label="Allow Users">
        <app-allowed-user-list></app-allowed-user-list>
      </mat-tab>
  </mat-tab-group>

</div>

import { Router } from '@angular/router';
import { RolesService } from '../services/roles.service';
import { AuthService } from '../services/auth.service';
import { mergeMap, map } from 'rxjs/operators';
import { ArticleService } from '../services/article.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/roles.service";
import * as i2 from "../services/auth.service";
import * as i3 from "@angular/router";
import * as i4 from "../services/article.service";
export class EditorGuard {
    constructor(rolesService, authService, router, articleService) {
        this.rolesService = rolesService;
        this.authService = authService;
        this.router = router;
        this.articleService = articleService;
    }
    canActivate(next, state) {
        // TODO: check this method later again if this is right
        /**
         * Checks if user is an editor, if so, then it may activate the editor component
         *
         */
        return this.authService.getLoggedInUser().pipe(mergeMap(result => {
            if (result) {
                return this.rolesService.detectEditor(result.email, '', '', '', '').pipe(mergeMap(isEditor => {
                    if (isEditor) {
                        return this.channelCheck(next.params.articleKey);
                    }
                    else {
                        this.router.navigate(['articles']);
                    }
                }));
            }
        }));
    }
    /**
     *
     * @param inArticleKey
     *
     * Checks if the user may activate the component, based on the channels the editor is authorized for.
     * If not authorized, the editor is redirected to the articlelist again.
     * TODO: Maybe redirect to an other page which gives some more info (contact administrator to add channels),
     * after 10 seconds it redirects the editor back to the article list.
     */
    channelCheck(inArticleKey) {
        return this.articleService.retrieveArticleObservable(inArticleKey).pipe(mergeMap(article => {
            return this.rolesService.mayEditByChannel(article).pipe(map(result => {
                if (result === true) {
                    return true;
                }
                else {
                    this.router.navigate(['articles']);
                    return false;
                }
            }));
        }));
    }
}
EditorGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EditorGuard_Factory() { return new EditorGuard(i0.ɵɵinject(i1.RolesService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.ArticleService)); }, token: EditorGuard, providedIn: "root" });

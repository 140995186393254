<form (ngSubmit)="saveArticle()" #articleForm="ngForm">
  <mat-toolbar class="actions-container accent-light-bc">
    <div class="container" fxLayout="row" fxLayoutAlign="space-between">
      <button type="button" mat-icon-button (click)="cancelArticle()" matTooltip="To article list">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <div fxLayoutAlign="center center" fxLayoutGap="5px"><span *ngIf="inputArticleModel.title">Editing: {{inputArticleModel.title}} </span> 
        <mat-icon *ngIf="inputArticleModel.published" matTooltip="This article is published">assignment_turned_in</mat-icon></div>
      <button type="button" mat-icon-button [matMenuTriggerFor]="articleMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

  </mat-toolbar>

  <div class="container article-container" fxLayout="column">
    <div fxFlex="100">
      <label for="title">
        <h2>Article title</h2>
      </label>
      <mat-form-field style="width:100%">
        <input matInput type="text" id="title" name="title" [(ngModel)]="inputArticleModel.title">
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <label for="summary">
        <h2>Summary</h2>
      </label>
      <mat-form-field style="width:100%">
        <textarea matInput type="text" id="summary" name="summary" rows="5" maxlength="280"
          [(ngModel)]="inputArticleModel.summary" [cdkTextareaAutosize]="true"></textarea>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <label for="content">
        <h2>Content</h2>
      </label>

      <div id="content" name="content" class="content" [froalaEditor]="froalaOptions" [(froalaModel)]="inputArticleModel.content">
      </div>

    </div>

<div fxFlex="100" *ngIf="inputArticleModel.imageUrls.length>0" >
  <label for="mainImage">
    <h2>Select main image</h2>
  </label>
  <div *ngFor="let imageSrc of inputArticleModel.imageUrls" (click)="selectMainImage(imageSrc)" 
                  [class.selected]="imageSrc===inputArticleModel.mainImageRef" style="cursor: pointer;">
                  <img src="{{imageSrc}}" style="height: 100px">
                </div>
  
</div>

<div fxFlex="100">

  <label for="channels">
    <h2>Channels </h2>
  </label>

  <mat-form-field style="width:100%">
    <mat-chip-list #articleChannelList>
      <mat-chip *ngFor="let articleChannel of inputArticleModel.channels">
        {{articleChannel | channelDef: this.inputAllChannelDefs}}
        <mat-icon matChipRemove (click)="removeFromArticleChannels(articleChannel)">cancel</mat-icon>
      </mat-chip>
      <input #articleChannelInput name="channels" matInput [matAutocomplete]="auto"
        [matChipInputFor]="articleChannelList" required>

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let channel of channels" (click)="addToArticleChannels(channel)">
          {{channel | channelDef: this.inputAllChannelDefs}}
        </mat-option>
      </mat-autocomplete>
    </mat-chip-list>


  </mat-form-field>

</div>
<div fxFlex="100">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-button type="submit" [disabled]="!articleForm.form.valid">Save</button>


    <button mat-button type="button" [disabled]="!articleForm.form.valid"
      (click)="publishArticle()">Publish</button>


  </div>
</div>

  </div>
  <div class="publish-container">
    <mat-toolbar>
      <div class="container" fxLayout="row" fxLayoutAlign="space-evenly">
        <div  fxLayout="row" fxHide.lt-sm>
          <span *ngIf="inputArticleModel.published; else notpublished">This article is published</span>
          <span fxLayoutAlign="start center">
            <mat-icon
              matTooltip="Last edit: {{inputArticleModel.lastUpdateDateInMs | date:'dd-MM-yy HH:mm '}} by {{inputArticleModel.lastUpdatedBy}}">
              info</mat-icon>
          </span>
        </div>
        <ng-template #notpublished>
          <span #notpublished>This article is not published</span>
        </ng-template>
        <button type="submit" mat-button [disabled]="!articleForm.form.valid">
          Save <mat-icon>save</mat-icon>
        </button>
        <button type="button" mat-button (click)="publishArticle()" [disabled]="!articleForm.form.valid" *ngIf="!inputArticleModel.published;else unpublishedButton">
          Publish <mat-icon>assignment_turned_in</mat-icon>
        </button> 
        <ng-template #unpublishedButton>
          <button type="button" mat-button (click)="publishArticle()" [disabled]="!articleForm.form.valid">
            Unpublish <mat-icon>assignment_turned_in</mat-icon>
          </button>
        </ng-template>
        <button  type="button" mat-button (click)="viewArticle()" [disabled]="!articleForm.form.valid">
          View <mat-icon>remove_red_eye
          </mat-icon>
        </button>
        <button type="button" mat-icon-button [matMenuTriggerFor]="articleMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        
      </div>
    </mat-toolbar>

  </div>
</form>

<mat-menu #articleMenu>
  <!-- Router navigate to view component -->
  <!-- <button mat-menu-item (click)="viewArticle()">View article</button> -->
  <button mat-menu-item (click)="deleteArticle()">Delete article</button>

</mat-menu>
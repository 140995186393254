<div fxLayout="column">

  <div>
    <h1 class="mat-dialog-title"  *ngIf="data.title">{{data.title}}</h1>
  </div>

  <div>
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="center">
      </div>
      <div *ngIf="data.body">{{data.body}} </div>
    </mat-dialog-content>

    <div class="mat-dialog-actions">
      <button mat-button (click)="closeDialog(true)" [mat-dialog-close]="true" *ngIf="data.affirmativeText">{{data.affirmativeText}}</button>
      <button mat-button (click)="closeDialog(false)" [mat-dialog-close]="false" *ngIf="data.declineText">{{data.declineText}}</button>

    </div>
  </div>

</div>
<div class="container" style="height:100%">


  <div fxLayout="row" fxLayout.lt-md="column" style="width:100%" fxLayoutAlign="center center">
    <div fxFlex="100" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" style="padding:10px 0 10px 0s"
      *ngIf="channelDefs$|async as channelDefs">
      <mat-form-field style="width:100%">
        <mat-select fxFlex="100"  placeholder="Select channel(s)" [(ngModel)]="selected" multiple>
          <mat-option *ngFor="let channel of channelDefs" (click)="filterList(this.selected)" [value]="channel.key">
            {{channel.name}} </mat-option>
  
        </mat-select>
      </mat-form-field>
   

    </div>

  </div>
  <div fxLayout="column">


    <mat-progress-bar *ngIf="loadingFilter"></mat-progress-bar>

  </div>


  <div *ngIf="articles$|async as articles;else errorOrLoading">
    <div *ngIf="channelDefs$|async as channelDefs">
      <app-deluxeview [articles]="articles" [articles$]="articles$" [channelDefs]="channelDefs"
        [isEditor]="rolesService.isEditor"></app-deluxeview>
      <!-- <app-article-plain-list [articles]="filteredArticles" [canEdit]="rolesService.isEditor" [channelDefs]="channelDefs"
      (open)="this.open.emit($event)" (edit)="this.edit.emit($event)" [expanded]="listExpanded">

    </app-article-plain-list> -->
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="this.selected.length===0">
      <button mat-icon-button (click)="loadMoreArticles(articles)">Load More<mat-icon>keyboard_arrow_down
        </mat-icon></button>
    </div>

  </div>


  <ng-template #errorOrLoading style="height:100%; width:100%">

    <div *ngIf="isError$|async;else loading">
      <p fxLayoutAlign="center center">Something went wrong, please try again</p>
    </div>

    <ng-template #loading>
      <div style="height:100%; width:100%" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
      </div>

    </ng-template>




  </ng-template>
  <div fxLayout="column" fxLayoutAlign="end end" *ngIf="rolesService.isEditor">
    <div fxFlex="100" class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newArticle()" class="sticky-add-button" matTooltip="New Article" [matTooltipPosition]="">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-list *ngFor="let admin of this.admins; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="adminLine">
    <div class="userline" (click)="editAdmin(admin)">
      <div class="image"><img src="{{admin.photoUrl}}" class="photo"></div>
      <div class="user">{{admin.name}} &lt;{{admin.email}}&gt;</div>
    </div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
  <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
    <button mat-fab (click)="newAdmin()" class="sticky-add-button" matTooltip="New Admin"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
  </div>
</div> 
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/storage";
export class FileService {
    constructor(afStorage) {
        this.afStorage = afStorage;
    }
    uploadFile(inPath, inFileList) {
        const file = inFileList.item(0);
        const task = this.afStorage.upload(inPath, file, {});
        return task;
    }
}
FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(i1.AngularFireStorage)); }, token: FileService, providedIn: "root" });


<mat-list *ngFor="let editor of this.editors; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="editorLine">
    <div class="userline" (click)="editEditor(editor)">
      <div class="image"><img src="{{editor.photoUrl}}" class="photo"></div>
      <div class="user">{{editor.name}} &lt;{{editor.email}}&gt;</div>
    </div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newEditor()" class="sticky-add-button" matTooltip="New Editor"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>
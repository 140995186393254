
<mat-list *ngFor="let apikey of this.apikeys; index as i">
  <mat-list-item [class.odd]="(i % 2 === 0)" [class.even]="(i % 2 !== 0)" class="apikeyLine">
    <div (click)="editApikey(apikey)">{{apikey.name}} : {{apikey.apiKey}}</div>
  </mat-list-item>
</mat-list>

<div fxLayout="column"  fxLayoutAlign="end end">
    <div fxFlex="100"  class="sticky-add-container" fxLayoutAlign="end center">
      <button mat-fab (click)="newApikey()" class="sticky-add-button" matTooltip="New Api Key"  [matTooltipPosition]="" ><mat-icon>add</mat-icon></button>
    </div>
  </div>
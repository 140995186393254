<div class="container">


  <form (ngSubmit)="saveDomain()" #channelForm="ngForm">
    <div fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="name"><h3>Domain Name</h3></label>
          <p class="helptext">Domain name of users that have access to the application</p>
        </div>
        <div fxFlex="100">
          <mat-form-field class="full-width">
            <input matInput id="name" name="name" placeholder="Domain name" [(ngModel)]="this.domain.domain">
          </mat-form-field>
        </div>
      </div>



      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <button mat-button type="button" (click)="HandleDelete()">Delete</button>
        <div style="width: 30px;"></div>
        <button mat-button type="button" (click)="handleCancel()">Cancel</button>
        <button mat-button type="submit" [disabled]="!channelForm.form.valid">Save</button>
      </div>

    </div>
  </form>
</div>


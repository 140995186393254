<div class="container">

  <mat-tab-group dynamicHeight>
    <mat-tab label="Editors">
      <app-editor-list></app-editor-list>
    </mat-tab>
    <mat-tab label="Channels">
      <app-channel-list></app-channel-list>
    </mat-tab>
    <mat-tab label="Logo">
      <app-fileupload [filePath]="'images/logo/logo.png'" [accept]="'image/*'"></app-fileupload>
    </mat-tab>  
  </mat-tab-group> 

</div> 
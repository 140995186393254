<div class="container">


  <form (ngSubmit)="saveEditor()" #channelForm="ngForm">
    <div fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="name"><h3>Channel name</h3></label>
        </div>
        <div fxFlex="100">
          <mat-form-field>
            <input matInput id="name" name="name" placeholder="Channel name" [(ngModel)]="this.channelDef.name">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column">
        <div fxFlex="100">
          <label for="name"><h3>Channel label</h3></label>
        </div>
        <div fxFlex="100">
          <mat-form-field>
            <input matInput id="label" name="label" placeholder="Channel label" [(ngModel)]="this.channelDef.label">
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <!--<button mat-button type="button" (click)="HandleDelete()">Delete</button>-->
        <!--<div style="width: 30px;"></div>-->
        <button mat-button type="button" (click)="handleCancel()">Cancel</button>
        <button mat-button type="submit" [disabled]="!this.isValid()">Save</button>
      </div>

    </div>
  </form>
</div>

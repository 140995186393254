<div>
    <div *ngIf="headArticle$|async as headArticle">

        <app-deluxe-head-item [headArticle]="headArticle" [articles]="articles" [channelDefs]="channelDefs"
            [isEditor]="isEditor" [inEditor$]="editor$"></app-deluxe-head-item>
    </div>
    <div *ngIf="publishedArticles$|async as publishedArticles" fxHide.lt-sm>

        <app-deluxe-other-items [articles]="publishedArticles" [channelDefs]="channelDefs" [isEditor]="isEditor" [inEditor$]="editor$">
        </app-deluxe-other-items>

    </div>

    <div class="article-container" id="all" #allArticles>

        <h1 fxHide.lt-sm>All articles </h1>
        <div *ngFor="let article of articles">
            <div fxLayout="row" fxLayoutGap="10px" class="article-row" (click)="openArticle(article)"
                *ngIf="isEditor || article.published">
                <div fxFlex="85" fxFlex.lt-sm="100">
                    <small>{{article.lastUpdateDateInMs|date}} | <span
                            *ngFor="let channel of article.channels; index as i">{{channel  | channelDef: this.channelDefs}}
                            <span *ngIf="i !== article.channels.length-1">, </span> </span>
                        <button mat-icon-button *ngIf="isEditor && (this.editor$|async|mayEdit: article)"
                            (click)="$event.stopPropagation(); editArticle(article)">
                            <mat-icon matTooltip="Edit article">edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="article.published && isEditor">
                            <mat-icon matTooltip="This article is published">assignment_turned_in</mat-icon>
                        </button>
                    </small>
                    <h3>{{article.title}}</h3>
                    <!-- <small>{{article.summary}}</small> -->
                </div>
                <div fxFlex="15" fxHide.lt-sm class="article-image"
                    [ngStyle]="{'background-image':'url('+article.mainImageRef+')'}">

                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxHide.lt-sm>
        <div fxFlex="100" class="sticky-add-container" fxLayoutAlign="end center">
            <button mat-button class="sticky-add-button all-articles-button" (click)="navigateToAll(allArticles)">
                <span>All articles </span>
                <mat-icon>keyboard_arrow_down
                </mat-icon>
            </button>
        </div>
    </div>

</div>